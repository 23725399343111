@import "../../../styles/vars";

.formSubmitSuccess {
  &.section {
    align-items: center;
  }
  .titleWrapper {
    margin-top: 0;
  }
}

.section {
  margin-top: 60px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: $desktop-width) {
    flex-direction: column;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.titleWrapper {
  text-align: right;
  font-size: 48px;
  font-family: $font-oswald;
  line-height: 1;
  padding: 5px 0;
  padding-right: 20px;
  margin-top: 100px;
  position: relative;
  @media (max-width: $desktop-width) {
    font-size: 36px;
    padding-right: 0;
    padding-left: 20px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 30px;
  }
  &:before {
    content: "";
    width: 4px;
    height: 100%;
    display: block;
    background-color: $color-secondary;
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: $desktop-width) {
      right: initial;
      left: 0;
    }
  }
}
.title {
  color: $color-primary;
  text-transform: uppercase;
  font-size: 75px;
  @media (max-width: $desktop-width) {
    font-size: 55px;
    margin-bottom: 5px;
  }
}

.formWrapper {
  width: 400px;
  font-size: 20px;
  @media (max-width: $desktop-width) {
    width: 550px;
  }
  @media (max-width: $tablet-width) {
    width: 90%;
  }
}

.submitButton {
  min-width: 200px;
}
